import { React } from 'react';

import { ArtistsTitle, SubTitle, CHTextBox } from "./Artists"
import styled, { css } from 'styled-components'
import { ReadLess, aws_cloudfront_domain_name, aws_s3_domain_name, GetS3CloudFrontJson } from '../../common/commonUtils';
import Header from '../navigation/Header';
import Footer from '../footer/Footer';
import Stack from '@mui/material/Stack';
import InstagramIcon from '@mui/icons-material/Instagram';
import "./Artists.css"

function getQueryStringKeysAndValues() {
    return window.location.href.split("?")[1].split(",").map(pair => pair.split("="));
}

function Body(props) {

    return (
        <div >
            <ArtistsTitle/>
            <SubTitle
                title={props.artistName}
            />
            <img
                className='read-more-artist-image'
                src={aws_cloudfront_domain_name + "/artists/" + props.artistPicture}
                alt={aws_s3_domain_name + "/artists/" + props.artistPicture}
            />
            <Stack
                className='artist-contact-info-read-more'
                direction="row"
                spacing={2}
            >
                <InstagramIcon/>
                <a style={{ textDecoration: 'none', color: 'black' }} href={`https://www.instagram.com/${props.igHandle}/`}>@{props.igHandle}</a>
            </Stack>
            <CHTextBox
                className='artist-bio-text-box'
                head={props.bioHead}
                body={props.bioBody}
                tail={props.bioTail}
            />
            <ReadLess
                page="team"
            />
    </div>
    )
}

export default function ArtistReadMorePage() {
    const artists = GetS3CloudFrontJson({filePath: "https://d1tpjvssbtzmqj.cloudfront.net/artists.json"})
    const owners = GetS3CloudFrontJson({filePath: "https://d1tpjvssbtzmqj.cloudfront.net/owners.json"})

    const artistKey = getQueryStringKeysAndValues()[0][1];
    const allArtists = {...owners, ...artists};
    const artist = allArtists[artistKey]

    const artistName = artist ? artist.name : ''
    const artistPicture = artist ? artist.picture : ''
    const backgroundPicture = 'ArtistReadMorePageDefaultBackground.jpg'
    const igHandle = artist ? artist.ig_handle : ''
    const bioHead = artist && artist.bio ? artist.bio.head : ''
    const bioBody = artist && artist.bio ? artist.bio.body : ''
    const bioTail = artist && artist.bio ? artist.bio.tail : ''

    const Container = styled.div`
    ${css`
        display: flex;
        min-width: 100vw;
        min-height: 100vh;
        flex-direction: column;
        justify-content: space-between;
        background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url("${aws_cloudfront_domain_name + '/artists/' + backgroundPicture}") no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    `}
    `;

    return (
        <Container>
            <Header/>
                <Body
                    artistName={artistName}
                    artistPicture={artistPicture}
                    igHandle={igHandle}
                    bioHead={bioHead}
                    bioBody={bioBody}
                    bioTail={bioTail}
                />
            <Footer/>
        </Container>
    )
}